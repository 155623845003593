.button {
    color: #FEFCFF;
    border: 1px solid rgb(116, 81, 248);
    color: rgb(116, 81, 248);
    border-radius: 8px;
    margin: 5px;
    height: 37px ;
    width: 60px;
    cursor: pointer;
    font-size: 15px;
    font-family: 'Gabarito', sans-serif;
}