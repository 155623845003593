
.finance {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header-cobranca{
    width: 1000px; 
    height: 10%;
    .icone{
      margin-bottom: 0px;
      width: 120px;
      height: 50px; 
    }
  
    .faixa{
      width: 100%;
      height: 20px;
      border-bottom: 2px solid #FAD40C;
      background: #6B4380; 
      margin-bottom: 5px;
    }
  }

  .bloco{
    
    width: 1000px;
    justify-content:space-around;
    overflow-x: auto;
    min-height: 400px;
    max-height: 750px;
    display: flex;
    flex-direction: column;

   .status-cobranca{
      display: flex;
      justify-content: end;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: 15px;
      .resumo-status{
        display: flex;
        align-items: center;
        font-weight: bold;
        font-family: 'Gabarito', sans-serif;
        .bota-status-cobranca{
          font-family: 'Gabarito', sans-serif;
          display: flex;
          justify-content: center;
          color: #FEFCFF;
          background-color: #df9035;
          border: 1px solid #df9035;
          border-radius: 5px;
          margin: 5px;
          height: 20px ;
          width: 100px;
          font-size: 13px;
        }
      }
    }

    .blocos{
      display: flex;
      align-items: center;
      justify-content: space-around;

      .loader{
        top: 40%;
        left: 0;
        width: 100%;
        height: 70px;
        justify-content: center;
        display: flex;
        position: fixed;
      }
      .formulario{
        padding: 10px;
        margin-left: 5px;
        width: 50%;
        .topico{
          font-size: 20px;
          font-weight: medium;
          font-family: 'Gabarito', sans-serif;
          /*font-family: 'Mada', sans-serif;*/
          color: #999;
        }
        .topico2{
          margin-bottom: 15px;
          font-family: 'Gabarito', sans-serif;
          font-weight: medium;
          /*font-family: 'Mada', sans-serif;*/
          color: #2b2a2b;
        }
      }
     .qrcode{
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        margin-top: -76px;

        justify-content: center;
        align-items: center;
      }
    }

    @media (max-width: 500px) {
      
      .blocos{
        //display: flex;
        display: flex;
        flex-direction: column;
        /*flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0px;*/
    
        .loader{
          top: 40%;
          left: 0;
          width: 100%;
          height: 20px;
          justify-content: center;
          display: flex;
          position: fixed;
        }
        .formulario{
          margin-top: 10px;
         // margin-bottom: 0px;
          padding: 0px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: start;

          button{
            height: 25px;
            margin: 0 auto;
    
           margin-top: 5px;
          // margin-bottom: 15px;
          }

          .items{
            width: 100%;
            margin-left: 20px;
            justify-content: start;
            display: flex;
          }
          .topico{
            width: 50%;
            font-size: 15px;
            margin-right: 5px;
            font-weight: medium;
            font-family: 'Gabarito', sans-serif;
            /*font-family: 'Mada', sans-serif;*/
            color: #999;
          }
          .topico2{
            width: 50%;
            font-size: 13px;
            font-family: 'Gabarito', sans-serif;
            font-weight: medium;
            /*font-family: 'Mada', sans-serif;*/
            color: #2b2a2b;
          }
        }
        .qrcode{
          display: flex;
          margin-left: 0px;
          margin-top: 0px;
          img{
            width: 200px;
            height: 200px;
          }
          button{
            height: 25px;
          }  
        }
      }
    }

    .pagamentos{
      display: flex;
      flex-direction: column;
      font-family: 'Gabarito', sans-serif;
     
    }

    .formas-pagamento{
      color: #6B4380;
      font-family: 'Gabarito', sans-serif;
      font-weight: bold;
      font-size: 8px;
      margin-bottom: 8px;
      margin-top: 40px;
      margin-left: 10px;
    }

    @media (max-width: 500px) {

      .pagamentos{
        display: flex;
       flex-direction: column;
       
      }
      .formas-pagamento{
        margin-bottom: 8px;
        margin-top: 20px;
        margin-left: 10px;
      }
    }
    .boleto{
      margin-bottom:5px;
      display: flex;
      margin-left: 10px;


      .boleto-button{
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        border: 1px solid #999;
        border-radius: 8px;
        padding: 2px;
        cursor: pointer;
        align-items: center;
        background-color: #6B4380;
        width: 40px;
        

        .text-boleto{
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 9px;
          font-family: 'Gabarito', sans-serif;
          color: #FEFCFF;
        }
      }
    }

    @media (max-width: 500px) {
      .boleto{
        margin-bottom: 10px;
        margin-left: 10px;

      }
    }

    .codigo-boleto{
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #2b2a2b;
      font-family: 'Gabarito', sans-serif;
      font-size: 15px;

      .boleto-copi{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left:10px ;
        margin-right: 30px;
        

        .botao-copiar{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;
          color: #FEFCFF;
          padding: 3px;
          background-color:#6B4380 ;
          border-radius: 5px;
          cursor: pointer;
        }

      }
    }

   @media (max-width: 500px) {
    .codigo-boleto{
      font-size: 11px;
      display: flex;
      flex-direction: column;
      button{
        height: 25px;
      }
      .boleto-copi{
        margin-bottom: 5px;
        .botao-copiar{
          span{
            height: 20px;
            width: 20px;
            font-size: 19px;
          }
            

        }
      }

    }
   }
  
  }

  .pe{
    width: 1000px; 
    height: 100%;
    .faixa-final{
      width: 100%;
      height: 20px;
      border-top: 2px solid #FAD40C;
      background: #6B4380; 
      margin-bottom: 5px;
      
    }
  }
}

.popupBoleto{
  display: flex;
  flex-direction: column;
  font-family: 'Gabarito', sans-serif;

  .botaoGerarBoleto{
    justify-content: end;
    display: flex;
    margin-top: 10px;

  }
}
@media (max-width: 500px) {

.finance {
  width: 100%;

  .header-cobranca{
    width: 100%;
    .icone{
      margin-bottom: 0px;
      width: 100px;
      height: 40px; 
    }
    .faixa{
      //display: none;
      height: 15px;
    } 

  }
  .bloco{
    width: 100%;

    .status-cobranca{
      display: flex;
      justify-content: start;
      margin-left: 15px;
      margin-bottom: 10px;
      margin-top: 5px;
      .resumo-status{
        color: #4b494b;
        font-size: 15px;
        display: flex;
        align-items: center;
        font-weight: bold;
        .bota-status-cobranca{
          display: flex;
          justify-content: center;
          color: #FEFCFF;
          background-color: #df9035;
          border: 1px solid #df9035;
          border-radius: 5px;
          margin: 5px;
          height: 16px ;
          width: 90px;
          font-size: 13px;
          font-weight: bold;

        }
      }
     }

  }


  .pe{
    width: 100%; 
    height: 100%;
    .faixa-final{
      width: 100%;
      height: 15px;
      //height: 20px;
      border-top: 2px solid #FAD40C;
      background: #6B4380; 
      margin-bottom: 5px;
      margin-top: 15px;
      
    }
  }
}

}
  