.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.788);

    display: flex;
    justify-content: center;
    align-items: center;

    .popup-inner{
        position: relative;
        padding: 32px;
        width: 100%;
        max-width: 540px;
        background-color: white;
        border-radius: 7px;
    
        .material-icons{
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
        }
    }
}